import "./App.css";
import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  useHistory,
  create,
  useLocation,
  Router,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "./pages/Home";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import Navbar from "./components/Home/Navbar";
import Footer from "./components/Home/Footer";
import Blog from "./components/Blog/Blog";
import Loyalty from "./components/modules/Loyalty";
import Point_of_sale from "./components/modules/Point_of_sale";
import Ecommerce from "./components/modules/Ecommerce";
import Payments from "./components/modules/Payments";
import Payment_in_person from "./components/modules/Payment_in_person";
import Accouting_ERPs from "./components/modules/Accouting_ERPs";
import Payments_on_website from "./components/modules/Payments_on_website";
import Resellers_program from "./components/modules/Resellers_program";
import Inventory_managment from "./components/modules/Inventory_managment";
import Developers from "./components/modules/Developers";
import Products from "./components/modules/Products";
import About_us from "./components/modules/About_us";
import Purchase from "./components/modules/Purchase";
import Help_center from "./components/modules/Help_center";
import Contact_sales from "./components/modules/Contact_sales";
import Retail from "./components/modules/Retail";
import Payments_risk_manage from "./components/modules/Payments_risk_manage";
import Invu_expert from "./components/modules/Invu_expert";
import Payment_on_computer from "./components/modules/Payment_on_computer";
import Large_business from "./components/modules/Large_business";
import Food_and_beverage from "./components/modules/Food_and_beverage";
import Professional_services from "./components/modules/Professional_services";
import Payment_platform from "./components/modules/Payment_platform";
import Transfers from "./components/modules/Transfers";
import Guides from "./components/modules/Guides";
import Health_and_fitness from "./components/modules/Health_and_fitness";
import Beauty_professionals from "./components/modules/Beauty_professionals";
import Coffee_shop from "./components/modules/Coffee_shop";
import Processor_payment from "./components/modules/Processor_payment";
import Business_types_general from "./components/modules/Business_types_general";
import Product_KDS from "./components/modules/Product_KDS";
import Product_Kiosks from "./components/modules/Product_Kiosks";
import Product_Marketing from "./components/modules/Product_Marketing";
import Products_Plus from "./components/modules/Products_Plus";
import Article_blog from "./components/Articles/Article_blog";
import Resources from "./components/modules/Resources";
import PageNotFound from "./components/modules/PageNotFound";
import Hardware_details from "./components/modules/Hardware_details";
import Article_blog_one from "./components/Articles/Article_blog_one";
import Home_repair_services from "./components/modules/Home_repair_services";
import Careers_main from "./components/Careers/Careers_main";
import Careers_details from "./components/Careers/Careers_details";
import Integrations from "./components/modules/Integrations";
import Pricing from "./components/modules/Pricing";
import Switch_invu from "./components/modules/Switch_invu";
import Whats_news from "./components/modules/Whats_news";
//New modules - Business types
import Restaurants from "./components/modules/Restaurants";
import Pubs from "./components/modules/Pubs";
import Minimarkets from "./components/modules/Minimarkets";
import Butcheries from "./components/modules/Butcheries";
import Fastfood from "./components/modules/Fastfood";
import Greengrocers from "./components/modules/Greengrocers";
import Retail_overview from "./components/modules/Retail_overview";
import Clothing from "./components/modules/Clothing";
import Furniture from "./components/modules/Furniture";
import Electronics from "./components/modules/Electronics";
import Movies from "./components/modules/Movies";
import Stadiums from "./components/modules/Stadiums";
import Arcades from "./components/modules/Arcades";
import Enterteiment_general from "./components/modules/Enterteiment_general";
import Bolos from "./components/modules/Bolos";
import Electronic_invoicing from "./components/modules/Electronic_invoicing";
import Fiscal_printers from "./components/modules/Fiscal_printers";
import Dealers from "./components/modules/Dealers";
import Counters from "./components/modules/Counters";
import Delivery_platform from "./components/modules/Delivery_platform";
import Productions from "./components/modules/Productions";
import Why_invu from "./components/modules/Why_invu";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import i18n from "./i18n";
import { Redirect } from "react-router";

//Blogs

import Article_accouting from "./components/Articles/Article_accouting";
import Article_DGI from "./components/Articles/Article_DGI";
import Article_pos_shops from "./components/Articles/Article_pos_shops";

import Thanks_sending from "./components/modules/Thanks_sending";
import Article_receive_payments from "./components/Articles/Article_receive_payments";
import Article_electronic_signature from "./components/Articles/Article_electronic_signature";
import Article_online_billing from "./components/Articles/Article_online_billing";
import Article_invu_processor from "./components/Articles/Article_invu_processor";
import Article_pos_restaurants from "./components/Articles/Article_pos_restaurants";
import Article_ecommerce_invu from "./components/Articles/Article_ecommerce_invu";
import Article_loyalty_invu from "./components/Articles/Article_loyalty_invu";
import Article_protect_earning from "./components/Articles/Article_protect_earning";
import Article_importance_software_loyalty from "./components/Articles/Article_importance_software_loyalty";
import Article_ecommerce_online_offline from "./components/Articles/Article_ecommerce_online_offline";
import Article_lose_matter from "./components/Articles/Article_lose_matter";
import Article_four_functions from "./components/Articles/Article_four_functions";
import Article_tips_design_web from "./components/Articles/Article_tips_design_web";
import Article_cash_flow from "./components/Articles/Article_cash_flow";
import Articles_sales_offline_online from "./components/Articles/Articles_sales_offline_online";
import Article_six_functions_pos from "./components/Articles/Article_six_functions_pos";
import Article_custom_email_solution from "./components/Articles/Article_custom_email_solution";
import Article_data_pos from "./components/Articles/Article_data_pos";
import Article_seven_tips_managment_restaurants from "./components/Articles/Article_seven_tips_management_restaurants";
import Article_online_ordering from "./components/Articles/Article_online_ordering";
import Article_marketing_restaurant from "./components/Articles/Article_marketing_restaurant";
import Article_ten_loyalty_industries from "./components/Articles/Article_ten_loyalty_industries";
import Hotel from "./components/modules/Hotel";
import Privacy_policies from "./components/modules/Privacy_policies";
import Cookie_policy from "./components/modules/Cookie_policy";
import CookiePolicy_info from "./components/modules/CookiePolicy_info";
import Demo_confirmed from "./components/modules/Demo_confirmed";
import Loyalty_program from "./components/modules/Loyalty_program";
import Article_seven_irresistible_benefits_invupos from "./components/Articles/Article_seven_irresistible_benefits_invupos";
import axios from "axios";
import LandingMX from "./pages/landingMX/LandingMX";
import Article_DGI_free_invoicer_in_panama from "./components/Articles/Article_DGI_free_invoicer_in_panama";
import Form_Contact from "./components/Home/Form_Contact";
import Demo_confirmed_external from "./components/modules/Demo_confirmed_external";
import FAQ from "./components/modules/FAQ";
import Article_nvidia_inception from "./components/Articles/Article_nvidia_inception";
import Article_restaurant_mexico_invupos from "./components/Articles/Article_restaurant_mexico_invupos";
import Article_maximizing_customer_loyalty from "./components/Articles/Article_maximizing_customer_loyalty";
import Article_alliance_with_cloudbeds from "./components/Articles/Article_alliance_with_cloudbeds";
import Article_gastronomic_future from "./components/Articles/Article_gastronomic_future";
import Article_management_inventory_hotel from "./components/Articles/Article_management_inventory_hotel";

// MX LANDING
import NavbarMX from "./pages/landingMX/NavbarMX/NavbarMX";
import FooterMX from "./pages/landingMX/FooterMX/FooterMX";
import NavbarVN from "./pages/landingVN/NavbarVN/NavbarVN";
import LandingVN from "./pages/landingVN/LandingVN";
import FooterVN from "./pages/landingVN/FooterVN/FooterVN";
import Article_in_the_cloud from "./components/Articles/Article_in_the_cloud";
import NavbarSV from "./pages/landingSV/NavbarSV/NavbarSV";
import LandingSV from "./pages/landingSV/LandingSV";
import FooterSV from "./pages/landingSV/FooterSV/FooterSV";
import Article_exploring_restaurant_systems from "./components/Articles/Article_exploring_restaurant_systems";
import BlogVN from "./components/Blog/BlogVN";
import BlogMX from "./components/Blog/BlogMX";
import Article_software_for_restaurants_mx from "./components/Articles/Article_softwarepos_for_restaurants_mx";
import Article_software_for_restaurants_sv from "./components/Articles/Article_softwarepos_for_restaurants_sv";
import BlogSV from "./components/Blog/BlogSV";
import Article_steps_to_implement_electronic_invoicing from "./components/Articles/Article_steps_to_implement_electronic_invoicing";
import Article_how_to_integrate_your_pos_system from "./components/Articles/Article_how_to_integrate_your_pos_system";
import Article_system_pos_and_delivery_platform_vn from "./components/Articles/Article_system_pos_and_delivery_platform_vn";
import NavbarCR from "./pages/landingCR/NavbarCR/NavbarCR";
import LandingCR from "./pages/landingCR/LandingCR";
import FooterCR from "./pages/landingCR/FooterCR/FooterCR";
import NavbarCL from "./pages/landingCL/NavbarCL/NavbarCL";
import LandingCL from "./pages/landingCL/LandingCL";
import FooterCL from "./pages/landingCL/FooterCL/FooterCL";
import Article_best_softwarepos_for_retaurants_in_panama from "./components/Articles/Article_best_softwarepos_for_restaurants_in_panama";
import Article_software_for_restaurants_cr from "./components/Articles/Article_software_for_restaurants_cr";
import BlogCR from "./components/Blog/BlogCR";
import BlogCL from "./components/Blog/BlogCL";
import Article_software_for_restaurants_cl from "./components/Articles/Article_software_for_restaurants_cl";
import Article_optimize_your_pos_system_for_unmatched_service from "./components/Articles/Article_optimize_your_pos_system_for_unmatched_service";
import Article_optimizing_the_supply_chain from "./components/Articles/Article_optimizing_the_supply_chain";
import Article_five_reasons_to_have_pos_cl from "./components/Articles/Article_five_reasons_to_have_pos_cl";
import Article_boosting_profitability_restaurant_pos_vn from "./components/Articles/Article_boosting_profitability_restaurant_pos_vn";
import Article_simplify_billing_and_payment_process from "./components/Articles/Article_simplify_billing_and_payment_process";
import Article_why_choose_cloud_pos_system from "./components/Articles/Article_why_choose_cloud_pos_system";
import Article_custom_pos_system_sv from "./components/Articles/Article_custom_pos_system_sv";
import Article_how_to_improve_customer_experience_mx from "./components/Articles/Article_how_to_improve_customer_experience_mx";
import Article_strategies_to_increase_sales_all from "./components/Articles/Article_strategies_to_increase_sales_all";
import Article_the_future_of_coffee from "./components/Articles/Article_the_future_of_coffee";
import Article_how_kds_systems_streamline from "./components/Articles/Article_how_kds_systems_streamline";
import Article_tips_and_tricks_from_a_chef from "./components/Articles/Article_tips_and_tricks_from_a_chef";
import Article_five_reasons_why_restaurant_needs_modern_web from "./components/Articles/Article_five_reasons_why_restaurant_needs_modern_web";
import Article_how_to_improve_the_customer_experience_in_bar from "./components/Articles/Article_how_to_improve_the_customer_experience_in_bar";


import { Select, MenuItem, FormControl, InputLabel, ListItemText, ListItemIcon } from '@mui/material'; // Importa los componentes necesarios de Material-UI
import { height } from "@mui/system";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Article_how_pos_system_can_help_boost_hotel_rest_sales from "./components/Articles/Article_how_pos_system_can_help_boost_hotel_rest_sales";
import Article_best_utensils_for_a_chef from "./components/Articles/Article_best_utensils_for_a_chef";
import Referrals from "./components/modules/Referrals";
import Article_importance_pos_system_event_management from "./components/Articles/Article_importance_pos_system_event_management";
import Article_the_benefits_of_online_delivery from "./components/Articles/Article_the_benefits_of_online_delivery";
import Article_guide_for_restaurants_in_panama from "./components/Articles/Article_guide_for_restaurants_in_panama";
import Barber from "./components/modules/Barber";
import Tatto_studios from "./components/modules/Tatto_studios";
import Dayspa from "./components/modules/Dayspa";
import Professional_services_module from "./components/modules/Professional_services_module";
import Article_how_an_effective_pos from "./components/Articles/Article_how_an_effective_pos";
import Theater from "./components/modules/Theater";
import Festival from "./components/modules/Festival";
import Food_truck from "./components/modules/Food_truck";
import Franchises from "./components/modules/Franchises";
import Cocina_fantasma from "./components/modules/Cocina_fantasma";
import Article_complete_guide_for_your_coffeeshop from "./components/Articles/Article_complete_guide_for_your_coffeeshop";
import Article_marketing_digital_for_barbers from "./components/Articles/Article_marketing_digital_for_barbers";
import Article_pos_system_tattoo_studio from "./components/Articles/Article_pos_system_tattoo_studio";
import Article_hero_integration from "./components/Articles/Article_hero_integration";
import Article_bestpos_system_spas_beautysalons from "./components/Articles/Article_bestpos_system_spas_beautysalons";
import Resources_gallery from "./components/modules/Resources_gallery";
import Hardware from "./components/modules/pressresources/Hardware";
import Vector_system from './components/modules/pressresources/Vector_system';
import Three_dimension from './components/modules/pressresources/Three_dimension';
import Kds from './components/modules/pressresources/Kds';
import Article_choose_the_best_system_craft_brewery from "./components/Articles/Article_choose_the_best_system_craft_brewery";
import Article_gift_cards_beatuy_salons from "./components/Articles/Article_gift_cards_beatuy_salons";
import Logos from "./components/modules/pressresources/Logos";
import Demo_figma from "./components/modules/Demo_figma";
import Article_food_truck from "./components/Articles/Article_food_truck";
import Article_abastur_mexico from "./components/Articles/Article_abastur_mexico";
import Case_studies from "./components/modules/Case_studies";
import Article_digital_wallets_for_restaurants from "./components/Articles/Article_digital_wallets_for_restaurants";
import Article_implementation_pos_software_in_rest_salvador from "./components/Articles/Article_implementation_pos_software_in_rest_salvador";

// Define el componente SelectorPais
const SelectorPais = ({ value, onChange }) => {
  return (
    <FormControl size="small">
      <Select
        labelId="country-select-label"
        id="country-select"
        sx={{
          width: 100,
          height: 35,
          background: '#FDFDFD',
          border: '#FDFDFD',
          fontSize: '0.920rem',
          borderRadius: 10,
        }}
        value={value}
        onChange={onChange}
        style={{ width: '160px' }}
      >
        <MenuItem value="panama">
          <span className="fi fi-pa"></span> &nbsp; <span>Panamá</span>
        </MenuItem>
        <MenuItem value="mexico">
          <span className="fi fi-mx"></span> &nbsp; <span>México</span>
        </MenuItem>
        <MenuItem value="venezuela">
          <span className="fi fi-ve"></span> &nbsp; <span>Venezuela</span>
        </MenuItem>
        <MenuItem value="elsalvador">
          <span className="fi fi-sv"></span> &nbsp; <span>El Salvador</span>
        </MenuItem>
        <MenuItem value="costarica">
          <span className="fi fi-cr"></span> &nbsp; <span>Costa Rica</span>
        </MenuItem>
        <MenuItem value="colombia">
          <span className="fi fi-co"></span> &nbsp; <span>Colombia</span>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export { SelectorPais };

const tagManagerArgs = {
  gtmId: "GTM-MLTH6XF",
};

TagManager.initialize(tagManagerArgs);

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/home/",
  },
});

ReactGA.initialize("G-PRG9923Z9F");

const baseRouteUrl = "/:locale(es|en|Panama|Mexico|Venezuela|Elsalvador|Costarica|Colombia)?";
export const baseUrl = i18n.language === "en" ? "" : "/" + i18n.language;

function App() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  console.log(lang);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const history = useHistory();
  const location = useLocation();

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });
  
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState(''); 
  const [selectedCountry, setSelectedCountry] = useState('');
  
  const countries = ['Panama', 'Mexico', 'Venezuela', 'Elsalvador', 'Costarica', 'Colombia'];

  const countryNameMappings = {
    'Costa Rica': 'Costarica',
    'El Salvador': 'Elsalvador',
    'México': 'mexico',
  };
  
  const adjustCountryName = (name) => {
    const adjustedName = countryNameMappings[name];
    return adjustedName ? adjustedName : name;
  };
  
  const getGeoInfo = (country) => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        const countryName = adjustCountryName(data.country_name);
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
  
        setCountry(country != null ? country : countryName);

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const compareCountry = (a, b) => a.toLowerCase() === b.toLowerCase();

  useEffect(() => {
    var country = null;
    if (lang.toLowerCase() === 'panama' || lang.toLowerCase() === 'mexico' || lang.toLowerCase() === 'venezuela' || lang.toLowerCase() === 'elsalvador' || lang.toLowerCase() === 'costarica' || lang.toLowerCase() === 'colombia') {
      country = lang;
    }
    getGeoInfo(country);
  }, []);

  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    const pathnameParts = location.pathname.split('/');
    const country = pathnameParts[0];
    if (firstLoad) {
      setFirstLoad(false);
      reloadPageWithSelectedCountry(country);
    } else {
      setSelectedCountry(country);
    }
  }, [location, firstLoad]);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    reloadPageWithSelectedCountry(selectedCountry);
  };

  const reloadPageWithSelectedCountry = (country) => {
    window.location.href = `/${country}`;
  };
  
  if (loading) {
    return (
      <div></div>
    );
  }

  return (
    <>
      <BrowserRouter>
     
        {/* <div class="px-4 py-0 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 flex justify-end mt-3">
          <SelectorPais value={country.toLowerCase()} onChange={handleCountryChange} />
        </div> */}

        {compareCountry(country, 'Mexico') || compareCountry(country, 'mexico') ? <NavbarMX /> :
          compareCountry(country, 'Venezuela') || compareCountry(country, 'venezuela') ? <NavbarVN /> :
          compareCountry(country, 'Elsalvador') || compareCountry(country, 'elsalvador') ? <NavbarSV /> :
          compareCountry(country, 'Costarica') || compareCountry(country, 'costarica') ? <NavbarCR /> :
          compareCountry(country, 'Colombia') || compareCountry(country, 'colombia') ? <NavbarCL /> :
          <Navbar />
        }

        <Switch>

          <Route
            exact
            path={baseRouteUrl + '/'}
            component={
              (compareCountry(country, 'Mexico') || compareCountry(country, 'mexico')) ? (LandingMX) :
              (compareCountry(country, 'Venezuela') || compareCountry(country, 'venezuela')) ? (LandingVN) :
              (compareCountry(country, 'Elsalvador') || compareCountry(country, 'elsalvador')) ? (LandingSV) :
              (compareCountry(country, 'Costarica') || compareCountry(country, 'costarica')) ? (LandingCR) :
              (compareCountry(country, 'Colombia') || compareCountry(country, 'colombia')) ? (LandingCL) :
              (Home)
            }
          />

          {/* Privacy Policies */}

          <Route exact path={baseRouteUrl + "/politicas-de-privacidad"}>
            <Privacy_policies/>
          </Route>

          {/* Cookie Policy Info */}
          <Route exact path={baseRouteUrl + "/politicas-de-cookies"}>
            <CookiePolicy_info/>
          </Route>

          {/* Products modules */}
          <Route exact path={baseRouteUrl + "/productos/punto-de-venta"}>
            <Point_of_sale />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/comercio-electronico"}>
            <Ecommerce />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/lealtad"}>
            <Loyalty />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/programa-de-lealtad"}>
            <Loyalty_program />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/plus"}>
            <Products_Plus />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/kds"}>
            <Product_KDS />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/quioscos"}>
            <Product_Kiosks />
          </Route>
          <Route exact path={baseRouteUrl + "/productos/marketing"}>
            <Product_Marketing />
          </Route>

          {/* Payments modules */}
          <Route exact path={baseRouteUrl + "/pagos/general"}>
            <Payments />
          </Route>
          <Route exact path={baseRouteUrl + "/pagos/pagos-en-persona"}>
            <Payment_in_person />
          </Route>
          <Route exact path={baseRouteUrl + "/pagos/pagos-en-su-sitio-web"}>
            <Payments_on_website />
          </Route>
          <Route exact path={baseRouteUrl + "/pagos/procesadores"}>
            <Processor_payment />
          </Route>
          <Route exact path={baseRouteUrl + "/pagos/facturacion-electronica"}>
            <Electronic_invoicing />
          </Route>
          <Route exact path={baseRouteUrl + "/pagos/impresoras-fiscales"}>
            <Fiscal_printers />
          </Route>

          {/* Food and beverage modules */}
          <Route exact path={baseRouteUrl + "/ayb/general"}>
            <Food_and_beverage country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/bares"}>
            <Pubs country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/restaurantes"}>
            <Restaurants country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/comida-rapida"}>
            <Fastfood country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/food-truck"}>
            <Food_truck country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/cafeterias"}>
            <Coffee_shop country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/hoteles"}>
            <Hotel country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/franquicias"}>
            <Franchises country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/ayb/cocinas-fantasmas"}>
            <Cocina_fantasma country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>

          {/* Retail modules */}

          <Route exact path={baseRouteUrl + "/venta-minorista/general"}>
            <Retail_overview country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/carnicerias"}>
            <Butcheries country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/minimercardo"}>
            <Minimarkets country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/fruterias"}>
            <Greengrocers country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/ropa"}>
            <Clothing country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/muebleria"}>
            <Furniture country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/venta-minorista/electronicos"}>
            <Electronics country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>

          {/* Professional services modules */}

          <Route exact path={baseRouteUrl + "/tipos-de-negocios/general"}>
            <Business_types_general country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/tipos-de-negocios/barberia"}>
            <Barber country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/tipos-de-negocios/estudios-de-tatuajes"}>
            <Tatto_studios country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route
            exact
            path={
              baseRouteUrl + "/tipos-de-negocios/profesionales-de-la-belleza"
            }
          >
            <Beauty_professionals country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route
            exact
            path={
              baseRouteUrl + "/tipos-de-negocios/spa-de-dia"
            }
          >
            <Dayspa country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route
            exact
            path={baseRouteUrl + "/tipos-de-negocios/salud-gimnasios"}
          >
            <Health_and_fitness country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route
            exact
            path={baseRouteUrl + "/tipos-de-negocios/servicios-profesionales"}
          >
            <Professional_services_module country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>

          {/* Entertainment modules */}

          <Route exact path={baseRouteUrl + "/entretenimiento/general"}>
            <Enterteiment_general country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/entretenimiento/cines"}>
            <Movies country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/entretenimiento/teatros"}>
            <Theater country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/entretenimiento/festivales"}>
            <Festival country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/entretenimiento/estadios"}>
            <Stadiums country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route exact path={baseRouteUrl + "/entretenimiento/bolos"}>
            <Bolos country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>
          <Route
            exact
            path={baseRouteUrl + "/entretenimiento/salones-de-juegos"}
          >
            <Arcades country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>

          {/* Large business module */}
          <Route exact path={baseRouteUrl + "/empresas-grandes/general"}>
            <Large_business country={country} handleCountryChange={handleCountryChange} selectedCountry={selectedCountry} />
          </Route>

          {/* Resources modules */}

          <Route exact path={baseRouteUrl + "/recursos/acerca-de-invu"}>
            <Why_invu />
          </Route>
          <Route exact path={baseRouteUrl + "/recursos/precios"}>
            <Pricing />
          </Route>
          
          <Route 
            exact 
            path={baseRouteUrl + "/recursos/blog"}
            component={
              (compareCountry(country, 'Venezuela') || compareCountry(country, 'venezuela')) ? (BlogVN) :
              (compareCountry(country, 'Mexico') || compareCountry(country, 'mexico')) ? (BlogMX) :
              (compareCountry(country, 'Elsalvador') || compareCountry(country, 'elsalvador')) ? (BlogSV) :
              (compareCountry(country, 'Costarica') || compareCountry(country, 'costarica')) ? (BlogCR) :
              (compareCountry(country, 'Colombia') || compareCountry(country, 'colombia')) ? (BlogCL) :
              (Blog)
            }
          />

          <Route exact path={baseRouteUrl + "/recursos/que-hay-de-nuevo"}>
            <Whats_news />
          </Route>
          <Route exact path={baseRouteUrl + "/recursos/cambiar-a-invu"}>
            <Switch_invu />
          </Route>

          <Route exact path={baseRouteUrl + "/recursos/referidos"}>
            <Referrals />
          </Route>

          <Route exact path={baseRouteUrl + "/recursos/general"}>
            <Resources />
          </Route>

          {/* Partners modules */}

          <Route exact path={baseRouteUrl + "/socios/distribuidores"}>
            <Dealers />
          </Route>
          <Route exact path={baseRouteUrl + "/socios/desarrolladores"}>
            <Developers />
          </Route>
          <Route exact path={baseRouteUrl + "/socios/plataforma-de-delivery"}>
            <Delivery_platform />
          </Route>
          <Route exact path={baseRouteUrl + "/socios/contadores"}>
            <Counters />
          </Route>

          {/* More tools modules */}

          <Route exact path={baseRouteUrl + "/mas-herramientas/integraciones"}>
            <Integrations />
          </Route>
          <Route exact path={baseRouteUrl + "/mas-herramientas/compras"}>
            <Purchase />
          </Route>
          <Route
            exact
            path={baseRouteUrl + "/mas-herramientas/contabilidad-erps"}
          >
            <Accouting_ERPs />
          </Route>
          <Route
            exact
            path={baseRouteUrl + "/mas-herramientas/gestion-de-inventarios"}
          >
            <Inventory_managment />
          </Route>
          <Route exact path={baseRouteUrl + "/mas-herramientas/producciones"}>
            <Productions />
          </Route>

          {/* About us module */}
          <Route exact path={baseRouteUrl + "/por-que-invu"}>
            <About_us />
          </Route>

          {/* Help modules */}

          <Route exact path={baseRouteUrl + "/centro-de-ayuda"}>
            <Help_center />
          </Route>

          {/* Other modules  */}

          <Route exact path={baseRouteUrl + "/contacto-con-ventas"}>
            <Contact_sales />
          </Route>
          <Route exact path={baseRouteUrl + "/expertos-de-invu"}>
            <Invu_expert />
          </Route>
          <Route exact path={baseRouteUrl + "/programas-de-revendedores"}>
            <Resellers_program />
          </Route>
          <Route exact path={baseRouteUrl + "/detalles-de-equipos"}>
            <Hardware_details />
          </Route>
          <Route exact path={baseRouteUrl + "/servicios-profesionales"}>
            <Professional_services />
          </Route>

          <Route exact path={baseRouteUrl + "/auth/login"}>
            <Login />
          </Route>
          <Route exact path={baseRouteUrl + "/auth/signup"}>
            <SignUp />
          </Route>

          <Route exact path={baseRouteUrl + "/products-overview"}>
            <Products />
          </Route>

          <Route exact path={baseRouteUrl + "/retail-overview"}>
            <Retail />
          </Route>
          <Route exact path={baseRouteUrl + "/payments/risk-manage"}>
            <Payments_risk_manage />
          </Route>
          <Route exact path={baseRouteUrl + "/payments/on-your-computer"}>
            <Payment_on_computer />
          </Route>
          <Route exact path={baseRouteUrl + "/payments/platform"}>
            <Payment_platform />
          </Route>
          <Route exact path={baseRouteUrl + "/transfers"}>
            <Transfers />
          </Route>
          <Route exact path={baseRouteUrl + "/resources/guides"}>
            <Guides />
          </Route>

          <Route exact path={baseRouteUrl + "/demo-prototipo"}>
            <Demo_figma/>
          </Route>

          <Route exact path={baseRouteUrl + "/resources/blog/article-one"}>
            <Article_blog />
          </Route>
          <Route exact path={baseRouteUrl + "/resource/blog/article-two"}>
            <Article_blog_one />
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/business-types/home-and-repair-services"}
          >
            <Home_repair_services />
          </Route>
          <Route exact path={baseRouteUrl + "/careers-invu"}>
            <Careers_main />
          </Route>
          <Route exact path={baseRouteUrl + "/careers-invu/details-job"}>
            <Careers_details />
          </Route>

          <Route exact path={baseRouteUrl + "/thanks-for-sending-message"}>
            <Thanks_sending />
          </Route>

          <Route exact path={baseRouteUrl + "/request-demo-confirmed"}>
            <Demo_confirmed/>
          </Route>

          <Route exact path={baseRouteUrl + "/request-demo-confirmed-external"}>
            <Demo_confirmed_external/>
          </Route>

          <Route exact path={baseRouteUrl + "/contact"}>
            <Form_Contact/>
          </Route>

          <Route exact path={baseRouteUrl + "/preguntas-frecuentes"}>
            <FAQ/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources"}>
            <Resources_gallery/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources/hardware"}>
            <Hardware/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources/logotypes-invu"}>
            <Logos/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources/vectores-del-sistema"}>
            <Vector_system/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources/3D"}>
            <Three_dimension/>
          </Route>

          <Route exact path={baseRouteUrl + "/gallery-resources/KDS"}>
            <Kds/>
          </Route>

          <Route exact path={baseRouteUrl + "/casos-de-exito"}>
            <Case_studies/>
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/blog/programas-de-contabilidad-en-la-nube"}
          >
            <Article_accouting />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/sistemas-punto-de-venta-para-tiendas-en-panama"
            }
          >
            <Article_pos_shops />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl + "/blog/como-recibir-pagos-en-linea-en-venezuela"
            }
          >
            <Article_receive_payments />
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/blog/firma-electronica-en-venezuela"}
          >
            <Article_electronic_signature />
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/blog/facturacion-electronica-dgi-2022"}
          >
            <Article_DGI />
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/blog/mejor-programa-de-facturacion-online"}
          >
            <Article_online_billing />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl + "/blog/invu-pos-el-mejor-procesador-de-pago-online"
            }
          >
            <Article_invu_processor />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl + "/blog/software-pos-para-restaurantes-en-venezuela"
            }
          >
            <Article_pos_restaurants />
          </Route>

          <Route exact path={baseRouteUrl + "/blog/e-commerce-con-invu-pos"}>
            <Article_ecommerce_invu />
          </Route>

          <Route exact path={baseRouteUrl + "/blog/loyalty-con-invu-pos"}>
            <Article_loyalty_invu />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/como-evitar-perder-materia-prima-en-los-inventarios"
            }
          >
            <Article_lose_matter />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/cuatro-funciones-del-sistema-invupos-para-mejorar-control-de-ventas"
            }
          >
            <Article_four_functions />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/consejos-para-diseñar-la-pagina-web-de-un-restaurante"
            }
          >
            <Article_tips_design_web />
          </Route>

          <Route
            exact
            path={baseRouteUrl + "/blog/que-es-el-cash-flow-y-como-mejorarlo"}
          >
            <Article_cash_flow />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl + "/blog/ventajas-de-unificar-con-el-sistema-pos"
            }
          >
            <Articles_sales_offline_online />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/seis-funciones-indispensables-en-el-punto-de-venta-para-controlar-las-sucursales"
            }
          >
            <Article_six_functions_pos />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/solucion-de-comercio-electronico-personalizada"
            }
          >
            <Article_custom_email_solution />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/los-datos-de-su-punto-de-venta-son-una-mina-de-oro"
            }
          >
            <Article_data_pos />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/siete-habilidades-esenciales-para-la-gestion-de-restaurantes"
            }
          >
            <Article_seven_tips_managment_restaurants />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/cuatro-beneficios-de-los-pedidos-en-linea-para-propietarios-de-restaurantes"
            }
          >
            <Article_online_ordering />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/como-utilizar-el-video-marketing-para-promocionar-su-restaurante"
            }
          >
            <Article_marketing_restaurant />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/diez-industrias-donde-los-programas-de-lealtad-son-extremadamente-efectivos"
            }
          >
            <Article_ten_loyalty_industries />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/siete-beneficios-irresistibles-de-integrar-invupos-con-la-facturacion-electronica"
            }
          >
            <Article_seven_irresistible_benefits_invupos />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/facturador-gratuito-de-la-DGI-en-Panamá"
            }
          >
            <Article_DGI_free_invoicer_in_panama />
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/invupos-eleva-su-potencial-al-unirse-al-programa-nvidia-inception"
           }
          >
            <Article_nvidia_inception/>  
          </Route>
          
          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/gastronomia-con-sabor-a-tecnologia-transformando-restaurantes-en-mexico-con-invupos"
           }
          >
            <Article_restaurant_mexico_invupos/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/maximizando-la-fidelizacion-de-los-clientes-con-invupos"
           }
          >
            <Article_maximizing_customer_loyalty/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/invupos-anuncia-una-estrategica-alianza-con-cloudbeds"
           }
          >
            <Article_alliance_with_cloudbeds/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/explorando-el-futuro-de-la-industria-de-restaurantes-en-2024"
           }
          >
            <Article_gastronomic_future/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/gestion-inteligente-de-inventarios-en-la-industria-hotelera"
           }
          >
            <Article_management_inventory_hotel/>  
          </Route>


          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/estilo-en-la-nube-como-invupos-transforma-la-industria-de-la-moda"
           }
          >
            <Article_in_the_cloud/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/software-pos-para-restaurantes-en-panama"
           }
          >
            <Article_exploring_restaurant_systems/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/software-pos-para-restaurantes-en-mexico"
           }
          >
            <Article_software_for_restaurants_mx/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/software-pos-para-restaurantes-en-el-salvador"
           }
          >
            <Article_software_for_restaurants_sv/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/pasos-para-implementar-la-facturacion-electronica-en-panama"
           }
          >
            <Article_steps_to_implement_electronic_invoicing/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-integrar-tu-sistema-pos-con-plataformas-de-delivery-en-el-salvador"
           }
          >
            <Article_how_to_integrate_your_pos_system/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/sistema-pos-y-plataformas-de-delivery-en-venezuela"
           }
          >
            <Article_system_pos_and_delivery_platform_vn/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/mejor-software-pos-para-restaurantes-en-panama"
           }
          >
            <Article_best_softwarepos_for_retaurants_in_panama/>  
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/software-pos-para-restaurantes-en-costarica"
           }
          >
            <Article_software_for_restaurants_cr/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/software-pos-para-restaurantes-en-colombia"
           }
          >
            <Article_software_for_restaurants_cl/>
          </Route>


          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-optimizar-tu-sistema-pos-para-un-servicio-inigualable"
           }
          >
            <Article_optimize_your_pos_system_for_unmatched_service/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/optimizando-la-cadena-de-suministros"
           }
          >
            <Article_optimizing_the_supply_chain/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/cinco-razones-para-tener-un-software-POS-en-tu-negocio"
           }
          >
            <Article_five_reasons_to_have_pos_cl/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/impulsando-la-rentabilidad-de-tu-restaurante-con-el-sistema-pos"
           }
          >
            <Article_boosting_profitability_restaurant_pos_vn/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/simplifica-la-facturacion-y-el-proceso-de-pago-como-invupos-agiliza-las-transacciones"
           }
          >
            <Article_simplify_billing_and_payment_process/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/por-que-elegir-un-sistema-pos-en-la-nube-la-mejor-opción-para-tu-negocio"
           }
          >
            <Article_why_choose_cloud_pos_system/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/sistemas-pos-personalizados-explorando-opciones-y-ventajas-competitivas"
           }
          >
            <Article_custom_pos_system_sv/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-mejorar-la-experiencia-del-cliente-y-aumentar-las-ventas"
           }
          >
            <Article_how_to_improve_customer_experience_mx/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/estrategias-para-aumentar-las-ventas-con-un-sistema-pos-en-tu-bar"
           }
          >
            <Article_strategies_to_increase_sales_all/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/el-futuro-del-cafe-tendencias-tecnologicas-que-debes-conocer-en-la-industria-cafetera"
           }
          >
            <Article_the_future_of_coffee/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/del-pedido-a-la-mesa-como-los-sistemas-kds-agilizan-el-flujo-de-trabajo-en-la-oficina"
           }
          >
            <Article_how_kds_systems_streamline/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/optimizando-tu-cocina-con-un-kds-consejos-y-trucos-de-un-chef"
           }
          >
            <Article_tips_and_tricks_from_a_chef/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/cinco-razones-por-las-que-tu-restaurante-necesita-una-pagina-web-moderna"
           }
          >
            <Article_five_reasons_why_restaurant_needs_modern_web/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-mejorar-la-experiencia-del-cliente-con-tecnologia-en-tu-bar"
           }
          >
            <Article_how_to_improve_the_customer_experience_in_bar/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-un-sistema-pos-puede-ayudar-a-impulsar-las-ventas-en-el-restaurante-del-hotel"
           }
          >
            <Article_how_pos_system_can_help_boost_hotel_rest_sales/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/descubre-donde-comprar-los-mejores-utensilios-para-un-chef"
           }
          >
            <Article_best_utensils_for_a_chef/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/la-importancia-de-un-sistema-pos-en-los-festivales-optimizando-la-experiencia-del-cliente-y-la-gestion-del-evento"
           }
          >
            <Article_importance_pos_system_event_management/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/los-beneficios-del-delivery-online"
           }
          >
            <Article_the_benefits_of_online_delivery/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/guia-para-restaurantes-en-panama-como-elegir-el-mejor-sistema-pos-para-tu-negocio"
           }
          >
            <Article_guide_for_restaurants_in_panama/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-un-sistema-pos-y-control-de-inventario-efectivos-pueden-hacer-la-diferencia"
           }
          >
            <Article_how_an_effective_pos/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/guia-completa-por-elegir-el-mejor-sistema-pos-para-tu-cafeteria"
           }
          >
            <Article_complete_guide_for_your_coffeeshop/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/marketing-digital-para-barberias-estrategias-para-atraer-y-retener-clientes"
           }
          >
            <Article_marketing_digital_for_barbers/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-elegir-el-mejor-sistema-pos-para-tu-estudio-de-tatuajes-una-guia-completa"
           }
          >
            <Article_pos_system_tattoo_studio/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/invupos-anuncia-nueva-integracion-con-hero-para-mejorar-la-contabilidad-y-gestion-financiera-de-sus-clientes"
           }
          >
            <Article_hero_integration/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/el-mejor-sistema-pos-para-spas-y-salones-de-belleza"
           }
          >
            <Article_bestpos_system_spas_beautysalons/>
          </Route>


          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-elegir-el-mejor-sistema-pos-para-tu-cerveceria-artesanal"
           }
          >
            <Article_choose_the_best_system_craft_brewery/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/como-los-gift-cards-pueden-impulsar-las-ventas-en-tu-salon-de-belleza"
           }
          >
            <Article_gift_cards_beatuy_salons/>
          </Route>


          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/simplifica-tu-food-truck-con-un-sistema-de-punto-de-venta-todo-en-uno"
           }
          >
            <Article_food_truck/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/anunciamos-nuestra-participacion-en-abastur-mexico-2024"
           }
          >
            <Article_abastur_mexico/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/las-wallets-digitales-y-sus-beneficios-para-tu-restaurante"
           }
          >
            <Article_digital_wallets_for_restaurants/>
          </Route>

          <Route
           exact
           path={
             baseRouteUrl +
             "/blog/beneficios-de-implementar-un-software-pos-en-restaurantes-de-el-salvador"
           }
          >
            <Article_implementation_pos_software_in_rest_salvador/>
          </Route>


          {/* <Route
            exact
            path={
              baseRouteUrl +
              "/blog/como-proteger-ganancias-en-restaurantes-con-tecnologia"
            }
          >
            <Article_protect_earning />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/importancia-de-usar-el-software-pos-con-sistema-de-lealtad"
            }
          >
            <Article_importance_software_loyalty />
          </Route>

          <Route
            exact
            path={
              baseRouteUrl +
              "/blog/sistema-pos-para-el-comercio-electronico-en-línea-y-fuera-de-linea"
            }
          >
            <Article_ecommerce_online_offline />
          </Route> */}

          {/* <Route
            path={"/zendesk"}
            component={() => {
              window.open("https://www.google.com", "_blank")?.focus();
              return history.back(-1);
            }}
          /> */}

          <Route component={PageNotFound} />


        </Switch>
{/* 
        {window.location.pathname === "/auth/login" ? null : <Footer /> &&
          window.location.pathname === "/auth/signup" ? null : (
          <Footer />
        )} */}

        {/* <Footer/> */}
        {/* {isFromMexico ? <FooterMX/> : <Footer/>} */}
        {/* {country === 'Mexico' ? <FooterMX /> : country === 'Venezuela' ? <FooterVN /> : <Footer />} */}
        {/* {country === 'Mexico' ? <FooterMX /> : country === 'Venezuela' ? <FooterVN /> : country === 'Salvador' ? <FooterSV /> : <Footer />} */}

        {compareCountry(country, 'Mexico') || compareCountry(country, 'mexico') ? <FooterMX country={country} handleCountryChange={handleCountryChange} /> :
          compareCountry(country, 'Venezuela') || compareCountry(country, 'venezuela') ? <FooterVN country={country} handleCountryChange={handleCountryChange} /> :
          compareCountry(country, 'Elsalvador') || compareCountry(country, 'elsalvador') ? <FooterSV country={country} handleCountryChange={handleCountryChange} /> :
          compareCountry(country, 'Costarica') || compareCountry(country, 'costarica') ? <FooterCR country={country} handleCountryChange={handleCountryChange} /> :
          compareCountry(country, 'Colombia') || compareCountry(country, 'colombia') ? <FooterCL country={country} handleCountryChange={handleCountryChange} /> :
          <Footer country={country} handleCountryChange={handleCountryChange} />
        }
        <Cookie_policy/>
      </BrowserRouter>
    </>
  );
}

export default withRouter(App);

