import React, { useState, useEffect } from 'react'
import CTA_home from '../Home/CTA_home'
import FAQ_business_types from './FAQ_business_types'
import FAQ_model_one from './FAQ_model_one'

const images = [
  "https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo4.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo5.jpg",
];

const Case_studies = () => {

  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <>

<article class="px-4 py-24 mx-auto max-w-6xl" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
  <div class="w-full mx-auto mb-10 text-left">
    <div class="pb-6 mb-3">
      <p className="inline-block mb-3 text-[14px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
      Ecosistema de Sisu
      </p>
      <h1 class="mb-0 text-5xl font-bold text-gray-900 md:leading-tight md:text-7xl" itemprop="headline" title="Rise of Tailwind - A Utility First CSS Framework">
      SISU COFFEE STUDIO
      </h1>
    </div>
    <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-coffee-studio1.jpg" class="object-cover obect-top w-full h-[500px]  rounded-3xl" alt="Kutty" />
    <div className='max-w-full'>
        <p class="text-lg mt-6 text-gray-500">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidata anim."</p>
    </div>
  </div>


  <section>
        <div class="max-w-screen-xl mx-auto">
          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="order-first w-full  max-w-screen-sm m-auto mt-12 lg:w-1/4 lg:order-first">
              <div class="p-4 transition  duration-500 ease-in-out transform bg-white border rounded-lg">
                <div class="flex py-2 mb-4 ">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-p26g21g_0uIlGbM6SrBDsGELRpffxj4T0Q&s"
                    class="w-12 h-12 rounded-full"
                  />
                  <div class="ml-3 mt-2 content-center">
                    <p class="text-sm font-medium text-gray-900">Sisu Coffee</p>
                    <p class="text-sm text-gray-500">- KDS</p>
                    <p class="text-sm text-gray-500">- Punto de venta</p>
                    <p class="text-sm text-gray-500">- Clau.io</p>

                  </div>
                </div>
                <div>
                  <div class="relative mt-auto text-center w-full inline-block px-8 py-3 text-white bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange rounded group">
                    <span class="text-sm font-medium transition-opacity group-hover:opacity-0">
                      Redes sociales
                    </span>

                    <ul class="absolute inset-0 flex items-center justify-center space-x-3 transition-opacity opacity-0 group-hover:opacity-100">
                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Twitter </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Instagram </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Facebook </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full px-4 mt-12 prose lg:px-0 lg:w-3/3">
              <h2 className="text-2xl text-gray-900 font-light md:text-6xl">
              Hizo que todo funcionara más rápido
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo1.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" />

              <h2 className="mt-16 text-2xl text-gray-900 font-light md:text-6xl">
              "Hizo que todo funcionara más rápido."
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>

              {/* <div class="mx-auto mt-6 max-w-2xl  lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 ">
                <div class="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg" alt="Two each of gray, white, and black shirts laying flat." class="h-full w-full object-cover object-center"/>
                </div>
                <div class="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
                    <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg" alt="Model wearing plain black basic tee." class="h-full w-full object-cover object-center"/>
                    </div>
                    <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo4.jpg" alt="Model wearing plain gray basic tee." class="h-full w-full object-cover object-center"/>
                    </div>
                </div>
                <div class="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo5.jpg" alt="Model wearing plain white basic tee." class="h-full w-full object-cover object-center"/>
                </div>
              </div> */}

              {/* <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" /> */}

                      <div className="carousel w-full relative overflow-hidden mt-8">
              {/* Slides */}
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`carousel-item w-full transition-opacity duration-700 ease-in-out ${
                    index === currentIndex ? "opacity-100" : "opacity-0 absolute"
                  }`}
                >
                  <img src={image} alt={`Slide ${index + 1}`} className="w-full object-cover w-full h-96 bg-center rounded-2xl" />
                </div>
              ))}

              {/* Prev & Next buttons */}
              <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                <button
                  onClick={prevSlide}
                  className="btn btn-circle bg-black bg-opacity-70 p-2 hover:bg-opacity-90 transition-all rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <button
                  onClick={nextSlide}
                  className="btn btn-circle bg-black bg-opacity-70 p-2 hover:bg-opacity-90 transition-all rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>

              <h2 className="mt-16 text-2xl text-gray-900 font-light md:text-6xl">
              Es muy cómodo.
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>

              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>

              <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" />

            </div>
          </div>
        </div>




      </section>

</article>

<section className="bg-gray-50">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-gray-50">
      <div className=''>
        <h2 className="mb-6 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Ecosistema de Invu POS en Sisu Coffee Studio
        </h2>
      </div>
      <div className="grid gap-4 row-gap-5 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device1.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device2.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device3.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>
      </div>
    </div>
    </section>

<CTA_home/>

<FAQ_business_types />

    </>
  )
}

export default Case_studies